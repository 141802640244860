exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-basic-plan-checkout-js": () => import("./../../../src/pages/basic-plan-checkout.js" /* webpackChunkName: "component---src-pages-basic-plan-checkout-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-commercial-plan-checkout-js": () => import("./../../../src/pages/commercial-plan-checkout.js" /* webpackChunkName: "component---src-pages-commercial-plan-checkout-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-dataset-js": () => import("./../../../src/pages/dataset.js" /* webpackChunkName: "component---src-pages-dataset-js" */),
  "component---src-pages-datasets-grid-js": () => import("./../../../src/pages/datasets-grid.js" /* webpackChunkName: "component---src-pages-datasets-grid-js" */),
  "component---src-pages-datasets-js": () => import("./../../../src/pages/datasets.js" /* webpackChunkName: "component---src-pages-datasets-js" */),
  "component---src-pages-exceeded-download-limit-js": () => import("./../../../src/pages/exceeded-download-limit.js" /* webpackChunkName: "component---src-pages-exceeded-download-limit-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-help-centre-js": () => import("./../../../src/pages/help-centre.js" /* webpackChunkName: "component---src-pages-help-centre-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insight-report-js": () => import("./../../../src/pages/insight-report.js" /* webpackChunkName: "component---src-pages-insight-report-js" */),
  "component---src-pages-insight-report-view-js": () => import("./../../../src/pages/insight-report-view.js" /* webpackChunkName: "component---src-pages-insight-report-view-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-open-data-blend-database-licence-js": () => import("./../../../src/pages/open-data-blend-database-licence.js" /* webpackChunkName: "component---src-pages-open-data-blend-database-licence-js" */),
  "component---src-pages-open-database-licence-js": () => import("./../../../src/pages/open-database-licence.js" /* webpackChunkName: "component---src-pages-open-database-licence-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-premium-plan-checkout-js": () => import("./../../../src/pages/premium-plan-checkout.js" /* webpackChunkName: "component---src-pages-premium-plan-checkout-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-level-agreement-js": () => import("./../../../src/pages/service-level-agreement.js" /* webpackChunkName: "component---src-pages-service-level-agreement-js" */),
  "component---src-pages-solution-partners-js": () => import("./../../../src/pages/solution-partners.js" /* webpackChunkName: "component---src-pages-solution-partners-js" */),
  "component---src-pages-standard-plan-checkout-js": () => import("./../../../src/pages/standard-plan-checkout.js" /* webpackChunkName: "component---src-pages-standard-plan-checkout-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-too-many-downloads-js": () => import("./../../../src/pages/too-many-downloads.js" /* webpackChunkName: "component---src-pages-too-many-downloads-js" */),
  "component---src-pages-unauthorised-request-js": () => import("./../../../src/pages/unauthorised-request.js" /* webpackChunkName: "component---src-pages-unauthorised-request-js" */),
  "component---src-pages-use-case-js": () => import("./../../../src/pages/use-case.js" /* webpackChunkName: "component---src-pages-use-case-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-video-gallery-js": () => import("./../../../src/pages/video-gallery.js" /* webpackChunkName: "component---src-pages-video-gallery-js" */),
  "component---src-pages-video-page-js": () => import("./../../../src/pages/video-page.js" /* webpackChunkName: "component---src-pages-video-page-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

